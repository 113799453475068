/*CSS */

.GridView-PDF {
  height: 100%;
  width: 100%;
}
.GridView-PDFContainer {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.GridView-flex {
  display: flex;
  flex-direction: column;
}

.GridView-row.row {
  overflow-y: hidden;
  margin: 0;
  height: 100%;
  flex-wrap: nowrap;
}

.adminView textarea.form-control {
  height: 250px;
}
/* Admin Overrides */

.adminView p,
.adminView h1,
.adminView h2,
.adminView h3,
.adminView label,
.adminView div {
  color: black;
}
.adminView {
  background-color: white;
  padding: 10px;
  height: 100%;
  /* border-radius: 10px; */
}
