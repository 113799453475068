.intro {
  text-align: center;
}
.hidden {
  visibility: hidden;
  opacity: 0;
}

.loading {
  transition: all 750ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 1000ms;
}
textarea.form-control {
  min-height: 120px;
}
.container.mini-container {
  max-width: 660px;
}

.tab-content {
  padding: 10px;
  cursor: default;
}
.nav-link {
  cursor: pointer;
}
.element {
  padding: 30px 0;
}
.element.inline {
  padding: 0;
}
.element.inline p {
  padding: 0;
  margin: 0;
}
.content {
  margin: 10px 0;
  position: relative;
  transition: all 600ms ease-in-out;
}
.kfpage {
  position: relative;
  overflow: hidden;
  max-height: 0px;
}
/* This fires as soon as the element enters the DOM */
.kfpage.kfpage-enter {
  /* position: absolute; */
  width: 100%;
  max-height: 1000px;
  -webkit-animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-top 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.kfpage .kfpage h1 {
  font-size: 30px;
}

/* This is where we can add the transition*/
.kfpage.kfpage-enter-done {
  max-height: fit-content;
}

/* This fires as soon as the this.state.showList is false */
.kfpage.kfpage-exit {
  position: absolute;
  top: 0;
  max-height: 1000px;
  -webkit-animation: fade-out-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* fires as element leaves the DOM*/
.kfpage.kfpage-exit-done {
  max-height: 0px;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-20 21:39:43
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-300px) scaleY(2.5) scaleX(1);
    transform: translateY(-300px) scaleY(2.5) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-300px) scaleY(2.5) scaleX(1);
    transform: translateY(-300px) scaleY(2.5) scaleX(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-8-20 21:40:53
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
}
