@font-face {
  font-family: "Avenir";
  src: url("/AvenirNextLTPro-Regular.woff") format("woff");
}
@font-face {
  font-family: "AvenirDemi";
  src: url("/AvenirNextLTPro-Demi.woff") format("woff");
}
@font-face {
  font-family: "Lato";
  src: url("/Lato-Regular.ttf");
}
.loading {
  background-image: linear-gradient(#081123, #192b47);
  background-attachment: fixed;
}

body.custom {
  background-image: linear-gradient(#081123, #192b47);
  background-attachment: fixed;
  font-family: "Avenir";
}

p,
h1,
h2,
h3,
label,
div {
  color: white;
}

.titleText {
  width: 100%;
  text-align: center;
  font-size: 40px;
  padding-bottom: 5vh;
  color: #efefef;
  font-family: "Avenir";
}

.titleText .subtitleText {
  font-size: 20px;
  color: #5083bf;
  color: white;
}

.titleTech {
  color: #5083bf;
}

.titleTechX {
  font-style: bold;
  font-size: 50px;
  font-family: "Lato";
}

.subtitleSubmitBy {
  font-style: bold;
  font-family: "AvenirDemi";
}
